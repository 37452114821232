<template>
    <div class="comp-select">
        <select v-model="comp.idx_office" @change="comp.update">
            <option value="0">본부 전체</option>
            <option :value="irow.idx" v-for="(irow, i) in comp.hq_office" :key="i">{{ irow.kname }}</option>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotDept',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        idx_sector: {
          type: [Number, String],
          default: 0,
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {

        const comp = reactive({
            hq_office  : [],
            is_updated : false,
            idx_office : 0,
            idx_sector : 0,

            doSearchOffice : () => {
                let params = {
                  idx_sector : comp.idx_sector
                };
                axios.get("/rest/component/getOfficeList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.hq_office = res.data.list;
                        setTimeout(function(){
                          comp.is_updated = false;
                        }, 100);
                    } else {
                        console.info(res);
                    }
                });
            },
            update: () => {
                emit('update:modelValue', comp.idx_office);
            }
        });

        watch(() => props.idx_sector, (value, preValue) => {
          if(preValue!=value) {
            comp.idx_sector = value;
            //comp.idx_office   = 0;
            //emit('update:modelValue', comp.idx_office);

            if( comp.is_updated == false ){
              comp.is_updated = true;
              comp.doSearchOffice();
            }
          }
        });

        watch(() => props.modelValue, (newVal, oldVal) => {
            if(newVal != oldVal && comp.idx_office != newVal) {
                comp.idx_office = props.modelValue;
            }
        });

        onMounted(() => {
            // Mounted
            comp.idx_sector = Number(props.idx_sector);
            comp.idx_office = props.modelValue;

            comp.doSearchOffice();
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.comp-select {
    display: inline-block;
    select {
        width: 100%;
        margin-right: 10px;
    }
}
</style>