import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    email: "",
    kname: "",
    ename: "",
    tinykey: "7pp3jy18mkg9q5tfdt11wgqr0q8yrzh8jw2sjetw9usfgato",
    staff: [],
    position: "",
    auth: "",
    pd_auth: false,
    gthr_auth: false,
    idx_office: 0,
    idx_team: 0,
    idx_position: 0,
    gnb: null,
    isMaster: false,
    isHrAdmin: false,
    isTotalMcgMcr: false,
    isCmsAdmin: false,
    isSalesAdmin: false,
    isManageSupportAdmin: false,
    isManageDisposalAdmin: false,
    isManageCompanyAdmin: false,
    isLead: false,
    isDevelopmentTeam: false,
    isHRDSite: false,
    isSaboArchiving: false,
    userLoginIdx: 0,
    userLoginUid: "",
    isToastVisible: false,
    isItsMeAdmin: false,

    isPlanningTeam: false, // 기획팀 키추가
    //명함보내기를 위한 임시 저장소
    bizCardInfo: {},
    //일지작성하기를 위한 임시 저장소
    scheduleOutsideWork: {},
  },
  //is_master : 모든 권한을 가짐.
  //is_hradmin : 인사/주유비 권한을 가짐.
  mutations: {
    clearUserInfo(state) {
      state.email = "";
      state.position = "";
      state.staff = [];
      state.auth = "";
      state.pd_auth = false; //피플팀 권한
      state.gthr_auth = false; // GTHR 권한
      state.idx_office = 0;
      state.idx_team = 0;
      state.idx_position = 0;
      state.idx_sector = 0;
      state.gnb = null;
      state.isMaster = false; //마스터 권한
      state.isHrAdmin = false; //인사본부장 권한
      state.isTotalMcgMcr = false;
      state.isSalesAdmin = false;
      state.isManageSupportAdmin = false;
      state.isManageDisposalAdmin = false;
      state.isLead = false;
      state.isCmsAdmin = false;
      state.isDevelopmentTeam = false;
      state.isHRDSite = false;
      state.isSaboArchiving = false;
      state.userLoginIdx = 0;
      state.userLoginUid = "";
      state.isItsMeAdmin = false;
      state.issmp = false;
    },
    setUserLoginInfo(state, userLoginInfo) {
      state.userLoginIdx = userLoginInfo.idx;
      state.userLoginUid = userLoginInfo.uid;
    },
    clearBizCardInfo(state) {
      state.bizCardInfo = {};
    },
    setBizCardInfo(state, bizCardInfo) {
      state.bizCardInfo = bizCardInfo;
    },
    clearScheduleOutsideWork(state) {
      state.scheduleOutsideWork = {};
    },
    setScheduleOutsideWork(state, scheduleOutsideWork) {
      state.scheduleOutsideWork = scheduleOutsideWork;
    },
    setUserInfo(state, userinfo) {
      state.email = userinfo.email;
      state.idx_office = userinfo.idx_office;
      state.idx_team = userinfo.idx_team;
      state.idx_staff = userinfo.idx;
      state.idx_position = userinfo.idx_position;
      state.idx_sector = userinfo.idx_sector;
      state.kname = userinfo.kname;
      state.ename = userinfo.ename;
      if (state.idx_office == 38) {
        state.auth = "accounting";
      } else {
        state.auth = "";
      }

      if (
        userinfo.idx_position == "6104" ||
        userinfo.idx_position == "6103" ||
        userinfo.idx_position == "6102" ||
        userinfo.idx_position == "6101" ||
        userinfo.idx_position == "6100"
      ) {
        state.position = "리드";
      } else {
        state.position = "매니저";
      }
    },
    setPosition(state, position) {
      state.position = position;
    },
    setStaff(state, staff) {
      state.staff = staff;
    },
    setGNB(state, gnb) {
      state.gnb = gnb;
    },
    setisToastVisible(state, parm) {
      state.isToastVisible = parm;
    },
    setAuth(state) {
      //애슐리:41, 시안:278, 패트릭: 135, 루이스 : 124, 제임스: 109, idx_sector 6167 : 경영위원
      if (
        ["41", "278", "124", "135", "109"].indexOf(String(state.idx_staff)) >
          -1 ||
        ["6167"].indexOf(String(state.idx_sector)) > -1
      ) {
        state.isMaster = true;
      }

      if (
        ["41", "99"].indexOf(String(state.idx_staff)) > -1 ||
        state.idx_team == "5994"
      ) {
        state.issmp = true;
      }

      //인사/휴가관리/유류관리 권한은 is_hrAdmin 으로 하고 루이스님이 가진다. 124
      if (["124"].indexOf(String(state.idx_staff)) > -1 || state.isMaster) {
        state.isHrAdmin = true;
      }

      if (["6100", "6101", "6102"].indexOf(String(state.idx_position)) > -1) {
        state.isTotalMcgMcr = true;
      }

      // cms 권한 대표이사 이상, 빌드업팀-ray님 226, Austin님 20426, Able팀-Dorothy님 3828
      if (
        ["6100", "6101", "6102"].indexOf(String(state.idx_position)) > -1 ||
        state.isMaster ||
        ["226", "20426", "3828", "4051"].indexOf(String(state.idx_staff)) > -1
      ) {
        state.isCmsAdmin = true;
      }

      //재무팀 권한
      if (state.idx_team == "6116" || state.isMaster || state.isHrAdmin) {
        state.isSalesAdmin = true;
      }

      //피플팀 권한
      if (
        state.idx_team == "5955" ||
        state.isMaster ||
        state.isHrAdmin ||
        ["20414", "20303"].indexOf(String(state.idx_staff)) > -1
      ) {
        state.pd_auth = true;
      }

      //총무팀 권한
      // 20576 총무팀 인턴 ( 인턴 끝나고 삭제 요망 )
      if (state.idx_team == "6115" || state.idx_staff == "20576" || state.isMaster || state.isHrAdmin) {
        state.isManageSupportAdmin = true;
      }

      //폐기리스트 관리 - 루이스, 애슐리, Master
      if (
        ["41", "124"].indexOf(String(state.idx_staff)) > -1 ||
        state.isMaster
      ) {
        state.isManageDisposalAdmin = true;
      }
      //리드 권한
      if (state.position == "리드" || state.isMaster || state.isHrAdmin) {
        state.isLead = true;
      }

      //GTHR 권한 GTHR,Bridge팀 + Mia님
      if (
        state.idx_team == "66" ||
        state.idx_team == "6136" ||
        state.idx_staff == "288" ||
        state.isMaster
      ) {
        state.gthr_auth = true;
      }

      //명함 엑셀 다운로드 권한(고객사 등록권한) 본부장, 부문대표 권한 , 총무팀 권한추가
      if (
        state.idx_team == "6115" ||
        ["6101", "6102"].indexOf(String(state.idx_position)) > -1 ||
        state.isMaster
      ) {
        state.isManageCompanyAdmin = true;
      }

      //개발 연구소 권한
      if (state.idx_office == 6095 || state.isMaster) {
        state.isDevelopmentTeam = true;
      }
      //HRD 사이트 접근권한 (컨퍼런스) : 본부장, 대표, 경영위원, 마케팅(S), 개발연구소
      // Chloe LS(이세희)
      if (
        ["6100", "6101", "6102", "6103"].indexOf(String(state.idx_position)) >
          -1 ||
        ["6167"].indexOf(String(state.idx_sector)) > -1 ||
        state.isMaster ||
        state.idx_team == "5994" ||
        state.idx_team == "6175" ||
        state.isDevelopmentTeam ||
        state.idx_staff === "20439"
      ) {
        state.isHRDSite = true;
      }
      //개발연구소, 마케팅, 디지털마케팅, 마케팅(S)
      if (
        state.isDevelopmentTeam ||
        state.idx_team == "5994" ||
        state.idx_office == 6137 ||
        state.idx_office == 6074
      ) {
        state.isSaboArchiving = true;
      }

      // it's me 스마트러닝 관리자 권한 (애슐리님, 컨텐츠플랫폼사업본부, 개발연구소, 마스터)
      if (
        state.idx_staff == 41 ||
        state.idx_office == 36 ||
        state.isDevelopmentTeam ||
        state.isMaster
      ) {
        state.isItsMeAdmin = true;
      }

      // 기획팀 기능 추가
      if (state.idx_team == "6173") {
        state.isPlanningTeam = true;
      }
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
