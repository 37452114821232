<template>
    <div class="team-select">
        <select v-model="comp.idx_team" @change="comp.update">
            <option value="0">팀 전체</option>
            <option :value="irow.idx" v-for="(irow, i) in comp.hq_team" :key="i">{{ irow.kname }}</option>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotTeam',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        idx_office: {
            type: [Number, String],
            default: 0,
        },
        idx_sector: {
          type: [Number, String],
          default: 0,
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {

        const comp = reactive({
            is_updated : false,
            hq_team    : [],

            idx_sector: 0,
            idx_office : 0,
            idx_team   : 0,

            doSearch : () => {
                let params = {
                    idx_sector : comp.idx_sector,
                    idx_office : comp.idx_office
                };
                axios.get("/rest/component/getTeamList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.hq_team = res.data.list;

                        setTimeout(function(){
                            comp.is_updated = false;
                        }, 100);
                    } else {
                        console.info(res);
                    }
                });
            },
            update: () => {
                emit('update:modelValue', comp.idx_team);
            }

        });
      watch(() => props.idx_sector, (value, preValue) => {
        if(preValue!=value) {
          comp.idx_sector = value;
          //comp.idx_team   = 0;
          //comp.idx_office  = 0;
          //emit('update:modelValue', comp.idx_team);
          if( comp.is_updated == false && Number(comp.idx_sector) > 0 ){
            comp.is_updated = true;
            comp.doSearch();
          }
        }
        if( comp.idx_sector==0){
          comp.doSearch();
        }
      });


        watch(() => props.idx_office, (value, preValue) => {
            if(preValue!=value) {
                comp.idx_office = value;
                //comp.idx_team   = 0;
                //emit('update:modelValue', comp.idx_team);

               // if( comp.is_updated == false && Number(comp.idx_office) > 0 ){
               //     comp.is_updated = true;
                    comp.doSearch();
               // }
            }
        });
        watch(() => props.modelValue, (newVal, oldVal) => {
          if(newVal != oldVal && comp.idx_team != newVal) {
            comp.idx_team = props.modelValue;
          }
          comp.doSearch();
        });
        watch(() => props.idx_team, (value, preValue) => {
            if(preValue!=value && value != comp.idx_team) {
                comp.idx_team   = value;

                if( comp.is_updated == false && Number(comp.idx_office) > 0 ){
                    comp.is_updated = true;
                    comp.doSearch();
                }
            }
        });

        onMounted(() => {
            // Mounted
            comp.idx_sector = Number(props.idx_sector);
            comp.idx_office = Number(props.idx_office);
            comp.idx_team   = Number(props.modelValue);

            // if( Number(comp.idx_office) > 0 ){
            comp.doSearch();
            // }
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
    .team-select {
        display: inline-block;
        select {
            width: 100%;
        }
    }
</style>