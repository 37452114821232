<template>
    <div class="carrot-fileuploader">
        <input ref="nfile" type="file" v-show="file.filename==''" @change="file.onFileSelected">
        <div v-show="file.filename!=''">
            <span class="btn-view mr-10">{{file.filename}}</span>
            <button class="btn-default dp-inblock h-30px" @click="file.onFileDelete()">삭제</button>
        </div>
    </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { useToast } from 'vue-toastification'

export default {
    name: 'CarrotUploader',
    components: {
    },
    props: {
        maxsize: {
            type: [Number, String],
            default: 3,
        },
        filename: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const nfile = ref(null);
        const toast = useToast()
        
        const file = reactive({
            maxsize : 3,
            filename: "",
            filedata: null,
            onFileSelected: (f) => {
                if(f.target.files.length == 1) {
                    if( f.target.files[0].size > file.maxsize*1024*1024 ) {
                        toast.error(file.maxsize + "메가 이상의 파일은 업로드 하실 수 없습니다.");
                        file.filename = '';
                        file.filedata = null;
                        nfile.value.value="";
                        return;
                    }
                    file.filename = f.target.files[0].name;
                    file.filedata = f.target.files[0];
                }
            },
            onFileDelete: () => {
                file.filename = '';
                file.filedata = null;
                nfile.value.value="";
            }
        });

        watch(() => props.filename, (newVal, oldVal) => {
            if(newVal != oldVal && file.filename != newVal) {
                file.filename = newVal;
            }
        })

        onMounted(() => {
            // Mounted
            file.maxsize = props.maxsize?Number(props.maxsize):50;
            file.filename = props.filename;
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {file, nfile};
    }

}
</script>
<style lang="scss" scoped>
</style>