<template>
    <div class="carrot-paging">
        <ul>
            <li @click="pg.setPage(pg.cur_page-1)">
                &lt; 
            </li>
            <li v-for="page in pg.pages" :key="page" :class="{'active':page==pg.cur_page}" @click="pg.setPage(page)">
                {{page}}
            </li>
            <li @click="pg.setPage(pg.cur_page+1)">
                &gt;
            </li>
        </ul>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive, watch } from 'vue'
import { useRouter } from 'vue-router';

export default {
    name: 'CarrotPaging',
    components: {
    },
    emits: [ 'update:modelValue', 'change' ],
    props: {
        modelValue: {
            type: Number,
            default: 1,
        },
        list_per_page: {
            type: Number,
            default: 30,
        },
        page_per_page: {
            type: Number,
            default: 5,
        },
        total: {
            type: [Number, String],
            default: 0,
        },
    },
    setup(props, context) {
        const router = useRouter();
        const pg = reactive({
            pages: [1, 2, 3, 4, 5],
            cur_page: 1,
            start: 0,
            end: 0,
            total_page: 0,
            prev_page: 0,
            next_page: 0,
            is_mounted : true,
            moveLink: (m) => {
                router.push(m.toLowerCase());
            },
            setPage: (page) => {
                if(page<1) {
                    page = 1;
                }
                if(page>pg.total_page) {
                    page = pg.total_page;
                }
                pg.cur_page = page;
                pg.start = pg.cur_page - pg.prev_page;
                pg.end = pg.cur_page + pg.next_page;
                if(pg.start<1) {
                    pg.end = pg.end + (1 - pg.start);
                    pg.start = 1;
                }
                if(pg.end>pg.total_page) {
                    pg.start = pg.start - (pg.end - pg.total_page);
                    pg.end = pg.total_page;
                }
                if(pg.start<1) {
                    pg.start = 1;
                }
                if(pg.end<1) {
                    pg.end = 1;
                }

                pg.pages = [];
                for(let i=pg.start;i<=pg.end;i++) {
                    pg.pages.push(i);
                }

                if(pg.is_mounted==true) {
                    pg.is_mounted = false;
                    // context.emit( 'update:modelValue', page);
                    // context.emit( 'change', page);
                } else {
                    context.emit( 'update:modelValue', page);
                    context.emit( 'change', page);
                }
            }
        });

        watch(() => props.total, (newVal, oldVal) => {
            if(newVal != oldVal && pg.total_page != newVal) {
                pg.cur_page = props.modelValue;
                if(pg.cur_page<0) {
                    pg.cur_page = 1;
                }

                pg.total_page = Math.ceil(props.total / props.list_per_page);
                pg.prev_page = Math.ceil((props.page_per_page-1)/2);
                pg.next_page = Math.floor((props.page_per_page-1)/2);
                pg.is_mounted = true;
                pg.setPage(pg.cur_page);
            }
        })
        
        onMounted(() => {
            // Mounted
            pg.cur_page = props.modelValue;
            if(pg.cur_page<0) {
                pg.cur_page = 1;
            }

            pg.total_page = Math.ceil(props.total / props.list_per_page);
            pg.prev_page = Math.ceil((props.page_per_page-1)/2);
            pg.next_page = Math.floor((props.page_per_page-1)/2);
            pg.is_mounted = true;
            pg.setPage(pg.cur_page);

            
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {pg};
    }

}
</script>
<style lang="scss" scoped>
.carrot-paging {
    text-align: center;
    font-size: 13px;
    margin-top: 40px;
    ul {
        li {
            margin: 0 10px;
            display: inline-block;
            color: #555;
            cursor: pointer;
            padding: 8px;
            &.active {
                color: #ff854a;
                font-weight: bold;
            }
        }
    }
}
</style>