<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-600px">
            <div v-if="pop.isLoaded==true&&pop.isShow==true" class="inner-layer">
                <div class="inner-pop">
                    <span class="btn-inner-close" @click="pop.isShow=false"><i class="fas fa-times"></i></span>
                    <img src="@/assets/img/thx_guide.jpg">
                    <div class="inner-txt">
                        <ul>
                            <li><span class="num">1. </span><div>나의 보유금액 : ${{ pop.info.thanks.remain }} (매월 1일 리셋)</div></li>
                            <li><span class="num">2. </span><div>보내는 금액 : 나의 보유금액에 한하여 보내기 가능(1일 한도 없음)</div></li>
                            <li><span class="num">3. </span><div>받을 사람을 잘 확인하여 선택하고 전달할 달러를 적은 후, <br>감사의 마음을 담아 <span class="bold">메시지를 꼭 작성</span>해 주세요.<br>보내기를 클릭하면 감사한 분께 메시지와 함께 달러가 전달 됩니다.</div></li>
                            <li><span class="num">4. </span><div>History에 내가 받고 보낸 메시지가 누적됩니다.<br>월 별로 최고의 감사달러를 받은 <span class="bold">상위 세분은 멘토링데이에서 시상합니다.</span></div></li>
                            <li><span class="num">5. </span><div>$500 금액은 이월되지 않고 매월 초에 리셋이 되므로,<br>동료가 베푼 호의들을 지나치지 마시고<br><span class="bold">아낌없이 달러로 감사의 마음을 전달해주세요.</span></div></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <p class="title">감사달러 <img @click="pop.isShow=true" src="@/assets/img/ico-info.png" alt="thanks info" class="btn-guide-show"></p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div v-if="pop.isLoaded==true" class="mt-10 tbl-light">
                <div class="pt-10">{{ pop.info.ename }}({{ pop.info.kname }})님의 현황</div>
                <p class="block-title">${{ pop.info.thanks.recv }} / {{ pop.info.thanks.send }}</p>
                <table class="tbl-light-view mt-20 w-100per">
                    <colgroup>
                        <col width="120">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>받는 사람</th>
                            <td><carrot-staff v-model="pop.idx_hq" class="w-100per"></carrot-staff></td>
                        </tr>
                        <tr>
                            <th>선물 달러</th>
                            <td><input type="text" v-model.trim="pop.send_dollar" class="w-50px" maxlength="3"> / ${{ pop.info.thanks.remain }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="preline"><textarea v-model.trim="pop.send_msg" class="w-100per h-50px"></textarea></td>
                        </tr>
                    </tbody>
                </table>
                <div class="layer-btn mt-10 w-100per dp-table">
                    <button @click="pop.doSubmit()" class="btn-default float-right">선물하기</button>
                </div>

                <p class="block-title pt-10 pb-10">감사 달러 History</p>
                <select v-model="pop.state" @change="pop.page=1;pop.doSearch();" class="w-200px">
                    <option value="RECV">받은 달러</option>
                    <option value="SEND">보낸 달러</option>
                </select>
                <table class="tbl-light-view mt-20 w-100per">
                    <colgroup>
                        <col width="90">
                        <col width="*">
                        <col width="80">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>날짜</th>
                            <th>감사 메시지</th>
                            <th>달러</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in pop.list" :key="i">
                            <td class="txt-center">{{ irow.wdate }}</td>
                            <td><div class="w-170px">{{ irow.contents }}</div></td>
                            <td class="txt-center">${{ irow.dollar }}</td>
                        </tr>
                        <tr v-if="pop.total==0">
                            <td colspan="3" class="txt-center">감사 달러 내역이 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
                <CarrotPaging :total="pop.total" :list_per_page="pop.rows" v-model="pop.page" @change="pop.doSearch" class="mt-10"></CarrotPaging>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import CarrotStaff from '@/components/common/CarrotStaff.vue';

export default {
    name: 'ThanksDollarListPopup',
    components: { 
        CarrotStaff 
    },
    props: {
        idx_hq: {
            type: [Number, String],
            default: 0,
        },
    },
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            isLoaded : false,
            isShow   : false,

            idx_hq : 0,
            send_dollar : 0,
            send_msg    : "",

            info : {},
            page  : 1,
            rows  : 10,
            state : "RECV",

            list : [], total : 0,

            doSubmit : () => {
                let params = {
                    target  : pop.idx_hq,
                    dollar  : pop.send_dollar,
                    message : pop.send_msg
                };

                if( !params.target ){
                    toast.error("받는 사람을 선택하세요.");
                    return;
                }

                if( !params.dollar ){
                    toast.error("선물 달러를 입력하세요.");
                    return;
                }

                if( pop.info.thanks.remain < params.dollar ){
                    toast.error("이번 달 감사달러를 모두 소진하였습니다.");
                    return;
                }

                axios.post("/rest/personal/sendThxDollar", params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("감사한 마음 잘 전달하였습니다!");
                        pop.idx_hq = 0;
                        pop.send_dollar = 0;
                        pop.send_msg = '';

                        pop.doSearchInfo();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSearchInfo : () => {
                let params = { };
                axios.get("/rest/personal/getGNBInfo", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;
                        pop.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch: () => {
                let params = {
                    page  : pop.page,
                    rows  : pop.rows,
                    state : pop.state
                };

                axios.get("/rest/personal/getMyThxDollarList", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;
                        pop.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onSuccess: () => {
                emit('update:showList', true);
                emit('close');
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            if(props.idx_hq>0) {
                pop.idx_hq = props.idx_hq;
            }
            pop.doSearchInfo();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
.carrot-layer {
    position:relative;
    .btn-close {
        float: right;cursor: pointer;padding: 5px;margin-top:-5px;margin-right:-5px;
    }
    .btn-guide-show {
        cursor:pointer;
    }

    .inner-layer {
        position:absolute; top:-1px; left:-1px; width:calc(100% + 2px); height:calc(100% + 2px); background-color:rgba(0, 0, 0, 0.7);
        .inner-pop {
            position:absolute; top:0; left:-4px;
            .btn-inner-close {
                position:absolute;top:0;right:0;width:20px;height:20px;z-index:3;line-height:20px;text-align:center;cursor:pointer;
            }
            .inner-txt {
                position:absolute; top:110px; left:40px; width:330px; z-index: 2;
                font-size:11px;
                li {
                    padding-bottom:8px;
                    .num {
                        padding-left:12px; float:left; background-image:url(~@/assets/img/thx_guide_num.jpg); background-position: left; background-size: 12px; background-repeat: no-repeat;
                    }
                    div {
                        width:305px; margin-left:25px; white-space:initial; word-break: break-all;
                    }
                }
            }
        }
    }
}
</style>