"use strict";

import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const instance = axios.create(config);

instance.interceptors.request.use(
  function (config) {
    // olivepop 메인 도메인 : olivepop.net, www.olivepop.net
    // 스테이지 도메인 : stagehq.olivepop.net, stagehqapi.olivepop.net
    // 그밖에는 로컬 도메인 : localhost
    // API 서버(당근농장)
    if (window.location.href.indexOf("stage") > -1) {
      //스테이지 도메인 인 경우
      //config.baseURL = window.location.protocol+"//beta.carrotians.net";
    } else if (window.location.href.indexOf("stage") > -1) {
      //로컬호스트 인 경우 vue.config.js 의 devServer 셋팅으로 대체하여 연결되도록 함.
      // config.baseURL = window.location.protocol+"//beta.carrotians.net";
      // config.baseURL = "https://beta.carrotians.net";
      // config.baseURL = "https://carrot.mxm.kr";
    } else {
      //그외(서비스) 인 경우
      //config.baseURL = window.location.protocol+"//old.carrotians.net";
    }
    //config.headers.post['Content-Type'] = 'application/json';
    //config.headers.put['Content-Type'] = 'application/json';
    //config.timeout = 1000;
    config.withCredentials = false;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
