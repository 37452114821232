<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">감사달러</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table v-if="pop.isLoaded==true" class="tbl-light-view mt-20 w-100per">
                    <colgroup>
                        <col width="150">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>TO</th>
                            <td>{{ pop.info.ename }}({{ pop.info.kname }})</td>
                        </tr>
                        <tr>
                            <th>선물 달러</th>
                            <td>{{ pop.info.dollar_txt }} $</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="preline">{{ pop.info.contents }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p class="txt-center mt-10">익명의 당근인이 감사달러와 메시지를 보냈습니다.</p> 
            <div class="layer-btn w-100per txt-center">
                <button @click="pop.onSuccess()" class="btn-default mt-20 dp-inblock">감사 달러함 이동</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';

export default {
    name: 'ThanksDollarViewPopup',
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        },
        showList: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'update:showList' ],
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            isLoaded : false,

            idx  : 0,
            info : {},

            doSearch: () => {
                let params = {
                    idx : pop.idx,
                };

                axios.get("/rest/personal/getMyThxDollar", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info  = res.data;
                        pop.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onSuccess: () => {
                emit('update:showList', true);
                emit('close');
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx = props.idx;

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
    .btn-close {
        float: right;
        cursor: pointer;
        padding: 5px;
        margin-top:-5px;
        margin-right:-5px;
    }
</style>