<template>
    <router-view/>
</template>

<style lang="scss">
    @import "src/style/reset.scss";
    @import "src/style/common.scss";
    @import "src/style/style.scss";
    @import "src/style/carrot-style.scss";
    .inline-block {
        display: inline-block;
    }
</style>
