<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">프로필 관리</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="profile-box">
                <img src="@/assets/img/noimage_1x1.png" :style="{ 'backgroundImage':'url('+pop.photoimg+')' }" @error="pop.setAltImg" alt="profile image" class="pf-img">
                <label class="btn-edit">
                    <input type="file" class="up-photo-file" ref="photoimg" @change="pop.changePhoto" accept="image/*">
                    <i class="fas fa-camera"></i>
                </label>
            </div>
            <div v-if="pop.isShowPWD==true" class="mt-10 tbl-light">
                <p class="block-title">비밀번호 변경하기</p>
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="120">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>현재 비밀번호</th>
                            <td><input type="password" v-model="pop.cur_pwd" class="w-100per" maxlength="20" autocomplete="off"></td>
                        </tr>
                        <tr>
                            <th>새 비밀번호</th>
                            <td class="txt-left">
                                <input type="password" v-model="pop.new_pwd" class="w-100per" maxlength="20" autocomplete="off">
                                <span class="font-11 txt-red ">* 영문, 숫자, 특수문자 조함. 8자리 이상</span>
                            </td>
                        </tr>
                        <tr>
                            <th>새 비밀번호 확인</th>
                            <td><input type="password" v-model="pop.new_rpw" class="w-100per" maxlength="20" autocomplete="off"></td>
                        </tr>
                    </tbody>
                </table>
                <div class="txt-right mt-10">
                    <button type="button" @click="pop.doSubmit" class="btn-default dp-inline">저장</button>
                </div>
            </div>
            <div v-else class="mt-10 h-30px">
                <div class="block-title float-left mt-5 mr-10"></div>
                <button type="button" @click="pop.showChangePWD()" class="btn-default float-left">비밀번호 변경하기</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'

export default {
    name: 'ProfileModPopup',
    props: {
    },
    setup(props, {emit}) {
        const toast = useToast();
        const photoimg = ref(null);

        const pop = reactive({
            photoimg   : "@/assets/img/avatar.png",
            defaultimg : "@/assets/img/avatar.png",
            cur_pwd    : "",
            new_pwd    : "",
            new_rpw    : "",

            setAltImg(event) { 
                event.target.src = pop.defaultimg;
            },

            showChangePWD : () => {
                pop.isShowPWD = true;
            },

            changePhoto : () => {
                // photo-img
                if(photoimg.value.files.length <= 0 ) {
                    return;
                }
                let f = photoimg.value.files[0];
                if( f.size > 5*1024*1024) {
                    toast.error("5메가 이상의 파일은 업로드 하실 수 없습니다.");
                    f = null;
                    return;
                }

                const reader = new FileReader();
                reader.onload = e => {
                    pop.photoimg = e.target.result;
                }
                // reader가 이미지 읽도록 하기
                reader.readAsDataURL(f);

                let formData = new FormData();
                if( photoimg.value.files.length > 0 ) {
                    formData.append("photoimg", photoimg.value.files[0]);
                }
                formData.append('cur_pwd', '');
                formData.append('new_pwd', '');
                formData.append('new_rpw', '');

                axios.post("/rest/mypims/modProfile", formData).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("변경하였습니다.");
                        pop.onClose();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit : () => {
                Swal.fire({
                    title : '프로필 관리',
                    text  : '저장하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let formData = new FormData();
                        // if( photoimg.value.files.length > 0 ) {
                        //     formData.append("photoimg", photoimg.value.files[0]);
                        // }
                        formData.append("photoimg", '');
                        formData.append('cur_pwd', pop.cur_pwd);
                        formData.append('new_pwd', pop.new_pwd);
                        formData.append('new_rpw', pop.new_rpw);

                        axios.post("/rest/mypims/modProfile", formData).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("변경하였습니다.");
                                pop.onClose();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doSearch : () => {
                let params = {};
                axios.get("/rest/personal/getGNBInfo", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        pop.photoimg = res.data.photouri;
                    } else {
                        console.info(res);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            pop.idx = props.idx;

            pop.doSearch();
        })
        
        return {pop, photoimg};
    }
}
</script>
<style lang="scss" scoped>
.profile-box {
    position:relative; width:200px; height:200px; text-align:center; margin:20px auto;
    .pf-img {
        width:200px; height:200px; border-radius:50%; background-position:center; background-size:cover; background-repeat:no-repeat;
    }
    .btn-edit {
        position:absolute; right:10px; bottom:0px; width:30px; height:30px; font-size: 30px; overflow:hidden; cursor: pointer;
        .fas {
            position:absolute; top:0; left:0; z-index:1; cursor: pointer;
            &::before {
                cursor:pointer;
            }
        }
        .up-photo-file {
            position:absolute; top:-30px; left:-30px; width:0px; height:0px; opacity:0.01; z-index:2; cursor: pointer;
        }
    }
}
</style>