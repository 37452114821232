<template>
    <div class="position-select">
        <select v-model="comp.idx_position" @change="comp.update">
            <option value="0">직급 전체</option>
            <option :value="irow.idx" v-for="(irow, i) in comp.list" :key="i">{{ irow.kname }}</option>
            <!-- <option value="6201">리드</option>
            <option value="6202">Professional 매니저</option>
            <option value="6203">Expert 매니저</option>
            <option value="6204">매니저</option> -->
        </select>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotPosition',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        type: {
            type: String,
            default: "H",
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const comp = reactive({
            idx_position:0,

            list        : [],

            doSearch: () => {
                let params = {
                    type : comp.type
                };
                axios.get("/rest/component/getPositionList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.list = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            update: () => {
                emit('update:modelValue', comp.idx_position);
            }

        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.idx_position = !nVal?0:nVal;
            }
        });

        onMounted(() => {
            // Mounted
            comp.idx_position = !props.modelValue?0:Number(props.modelValue);
            comp.type         = props.type?props.type:"H";

            comp.doSearch();
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
    .position-select {
        display: inline-block;
        select {
            width: 200px;
        }
    }
</style>