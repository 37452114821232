<template>
  <div class="carrot-layer-bg">
    <JobRotationApplicantAddPopup :idx="pop.idx" v-if="pop.isShow" @close="pop.hideAddApplicant"></JobRotationApplicantAddPopup>
    <div class="carrot-layer w-600px">
      <div class="txt-center po-relative pt-40">
        <p class="title">Job Rotation System</p>
        <span class="btn-close type1" @click="pop.handloCloseModal()"><i class="fas fa-times"></i></span>
      </div>
      <div class="mt-20 tbl-light">
        <div class="info-box txt-center">
          <div class="desc">CARROTian의 조직 내 성장과 CARROT의 성공적인 조직 운영을 위해<br /><strong class="bold">Job Rotation</strong> 제도를 실시합니다.</div>
          <div class="borderBg-box mt-30">
            <div class="inbox ttbox1">
              <span class="tit">Job Rotation System이란?</span>
              <p class="txt mt-10">모든 CARROTian이 본인이 역량을 최대한 발휘하여<br />더욱 행복한 조직생활을 누릴 수 있도록 고안된 제도입니다.</p>
            </div>
            <div class="inbox ttbox2 txt-left">
              <p><strong class="tit">직무 로테이션</strong><span class="txt ml-5">: 현 직무 외에 도전해 보고 싶은 직무가 있을 경우 작성해주세요.</span></p>
              <p><strong class="tit">조직 로테이션</strong><span class="txt ml-5">: 현 조직 외에 함께 일하고 싶은 조직이 있을 경우 작성해주세요.</span></p>
            </div>
          </div>
          <div class="fill txt-right mt-10">※ 작성하시는 모든 내용은 보안이 유지됩니다.</div>
          <div class="period mt-15">작성기간 : {{ pop.info.sdate_txt }} ~ {{ pop.info.edate_txt }}</div>
        </div>
      </div>
      <div class="layer-btn mt-20 mb-10 txt-center">
        <button @click="pop.showAddApplicant" class="btn-default dp-inblock type1">작성하러 가기</button>
      </div>
      <div class="modalOnOff">
        <div class="fill txt-right">오늘 하루 열지 않기</div>
        <input type="checkbox" @click="pop.onClose()" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import JobRotationApplicantAddPopup from "@/components/popup/myPIMS/JobRotationApplicantAddPopup.vue";

export default {
  components: {
    JobRotationApplicantAddPopup,
  },
  name: "JobRotationPopup",
  props: {},
  setup(props, { emit }) {
    const toast = useToast();

    const today = new Date();
    // const saveDay = today.getDate();

    const pop = reactive({
      localDate: "",
      isShow: false,
      idx: 0,
      info: {},

      showAddApplicant: () => {
        pop.idx = pop.info.idx_jobrotation;
        pop.isShow = true;
      },

      hideAddApplicant: () => {
        pop.isShow = false;
        pop.onClose();
        pop.handloCloseModal();
      },

      doSearch: () => {
        let params = {};
        axios.get("/rest/personal/getJobRatationInfo", { params: params }).then((res) => {
          if (res.data.err == 0) {
            pop.info = res.data;

            if (pop.info.is_show_jobrotation == false) {
              pop.onClose();
            }
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      handloCloseModal: () => {
        emit("close");
      },

      onClose: () => {
        const yyyy = today.getFullYear();
        const mm = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : `0${today.getMonth() + 1}`;
        const dd = today.getDate() > 9 ? today.getDate() : `0${today.getDate()}`;
        localStorage.setItem("modalCloseTime", `${yyyy}-${mm}-${dd}`);
        localStorage.setItem("closeModal", 0);
        emit("close");
      },
    });

    onMounted(() => {
      // Mounted
      pop.doSearch();
    });

    return { pop };
  },
};
</script>
<style lang="scss" scoped>
.modalOnOff {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
