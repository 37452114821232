<template>
    <div class="dp-inblock">
        <select v-model="comp.apm" class="w-80px mr-5" @change="comp.change">
            <option value="AM">AM</option> 
            <option value="PM">PM</option> 
        </select>
        <select v-model="comp.hour" class="w-80px mr-5" @change="comp.change">
            <option value="">선택</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
        </select>
        <select v-model="comp.minute" class="w-80px" @change="comp.change">
            <option value="">선택</option>
            <option value="00">00</option> 
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option> 
            <option value="40">40</option>
            <option value="50">50</option>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'

export default {
    name: 'CarrotTimeSelect',
        props: {
        modelValue: {
            type: [Number, String],
            default: "00:00",
        },
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {

        const comp = reactive({
            apm    : "AM",
            hour   : "",
            minute : "",

            setTime : () => {
                if( props.modelValue.length == 5 ) {
                    let val = props.modelValue.split(":");
                    if( val.length == 2 ) {
                        comp.hour   = val[0];
                        comp.minute = val[1];

                        if( Number(comp.hour) > 12 ){
                            let th = Number(comp.hour) - 12;
                            comp.hour = th>9?""+th:"0"+th;
                            comp.apm  = "PM";
                        } else {
                            comp.apm  = (Number(comp.hour) == 12) ? "PM" : "AM";
                            if(Number(comp.hour) < 1) comp.hour = 12;
                        }
                    }
                } else {
                    comp.apm    = "AM";
                    comp.hour   = "";
                    comp.minute = "";
                }
            },

            change: () => {
                if( comp.apm && comp.hour && comp.minute ){
                    let ctime = ((comp.apm=="PM" && comp.hour < 12)?(Number(comp.hour)+12):comp.hour) + ":" + comp.minute;
                    if(comp.apm=="AM" && comp.hour==12) ctime = (Number(comp.hour)-12) + "0:" + comp.minute;
                    console.log(ctime);
                    emit('update:modelValue', ctime);
                }
            }
        });

        onMounted(() => {
            // Mounted
            comp.setTime();
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.setTime();
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
</style>