<template>
    <div class="staff-select">
        <select v-model="comp.idx_staff" @change="comp.update" :style="{ 'height':comp.height }">
            <option value="0">{{comp.title}}</option>
            <template v-for="(irow, i) in comp.staff" :key="i">
                <option :value="irow.idx" v-if="comp.showCheck(irow)" >{{ (comp.type==''||comp.type=='ename')?irow.ename:'' }}{{ (comp.type==''||comp.type=='kname')?'('+irow.kname+')':'' }}</option>
            </template>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import axios from '@/plugins/axios.js'
import { useStore } from 'vuex'

export default {
    name: 'CarrotStaff',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        idx_office: {
            type: [Number, String],
            default: 0,
        },
        idx_team: {
            type: [Number, String, Object],
            default: 0,
        },
        idx_sector: {
          type: [Number, String],
          default: 0,
        },
        type: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "전체",
        },
        orderby:{
            type: String,
            default: "easc"
        },
        height:{
            type: String,
            default:'30px'
        },
        isHeadTeamManagement: {
          type: String,
          default: "N",
        },
        deptlv: {
          type: [Number, String],
          default: 0,
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const store = useStore();
        const comp = reactive({
            is_updated : false,
            staff    : [],
            title : "전체",
            idx_sector : 0,
            idx_office : 0,
            idx_team   : 0,
            idx_staff  : 0,
            type       : "",
            orderby    : "easc",
            isHeadTeamManagement:"N",
            deptlv:0,

            showCheck : (staff) => {
                let check = false;
                if(comp.idx_team>0 && (staff.idx_office==comp.idx_team || staff.idx_team==comp.idx_team)) {
                    check = true;
                } else if(comp.idx_team==0 && comp.idx_office>0 && (staff.idx_office==comp.idx_office || staff.idx_team==comp.idx_office)) {
                    check = true;
                } else if(comp.idx_office == 0 && comp.idx_team == 0) {
                    check = true;
                }
                return check;
            },

            getStaff : () => {

              /*
                if(store.state.staff.length>0) {
                    comp.staff = store.state.staff;
                    return;
                }

               */
                let params = {
                    idx_sector : comp.idx_sector,
                    idx_office : comp.idx_office,
                    idx_team   : comp.idx_team,
                    orderby    : comp.orderby
                };
                axios.get("/rest/component/getStaff", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.staff = res.data.list;
                        store.commit('setStaff', comp.staff);
                    } else {
                        console.info(res.data);
                    }
                });
            },

            update: () => {
                emit('update:modelValue', comp.idx_staff);
            }
        });
        watch(() => props.modelValue, (value, preValue) => {
            if(preValue!=value) {
                comp.idx_staff = value;
            }
          comp.getStaff();
        });
      watch(() => props.idx_sector, (value, preValue) => {
        console.info(value);
        if(preValue!=value) {
          comp.idx_sector = value;
          comp.idx_office  = 0;
          comp.idx_team  = 0;
          comp.idx_staff = 0;
          comp.getStaff();
          emit('update:modelValue', comp.idx_staff);
        }
      });

      watch(() => props.idx_office, (value, preValue) => {
            if(preValue!=value) {
                comp.idx_office = value;
                comp.idx_team   = 0;
                comp.idx_staff  = 0;
                emit('update:modelValue', comp.idx_staff);
            }
        });
        watch(() => props.idx_team, (value, preValue) => {
            console.info(value);
            if(preValue!=value) {
                comp.idx_team  = value;
                comp.idx_staff = 0;
                emit('update:modelValue', comp.idx_staff);
            }
        });
      watch(() => props.deptlv, (value, preValue) => {
        console.info(value);
        if(preValue!=value) {
          comp.deptlv=value;
          if( comp.isHeadTeamManagement=='Y' && comp.deptlv==0){
            comp.title="대표이사 설정";
          }
          if( comp.isHeadTeamManagement=='Y' && comp.deptlv==1){
            comp.title="본부장 설정";
          }
          if( comp.isHeadTeamManagement=='Y' && comp.deptlv==2){
            comp.title="팀장 설정";
          }
          emit('update:modelValue', comp.deptlv);
        }
      });
        onMounted(() => {
            // Mounted
            comp.idx_sector = Number(props.idx_sector);
            comp.idx_office = Number(props.idx_office);
            comp.idx_team   = (typeof props.idx_team=="object")?props.idx_team:Number(props.idx_team);
            comp.idx_staff  = Number(props.modelValue);

            comp.isHeadTeamManagement  = props.isHeadTeamManagement;
            comp.deptlv  = Number(props.deptlv);
            comp.type       = props.type;
            comp.orderby    = props.orderby;
            comp.height     = props.height;
            comp.title      = props.title;


            comp.getStaff();

            if( comp.isHeadTeamManagement=='Y' && comp.deptlv==0){
              comp.title="대표이사 설정";
            }
            if( comp.isHeadTeamManagement=='Y' && comp.deptlv==1){
              comp.title="본부장 설정";
            }
            if( comp.isHeadTeamManagement=='Y' && comp.deptlv==2){
              comp.title="팀장 설정";
            }
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.staff-select {
    display: inline-block;
    select {
        width: 100%;
        height: 30px;
        max-height:30px;
        margin-right: 10px;
    }
}
</style>