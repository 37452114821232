<template>
    <div class="con-top">
        <h2 class="con-top-title">{{title}}</h2>
        <h3 class="con-top-txt">
            <slot></slot>
        </h3>
    </div> 
</template>

<script>
export default {
    props: ['title'],
    name: 'CarrotTitle',
}
</script>
<style lang="scss" scoped>
</style>