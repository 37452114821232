<template>
  <div class="carrot-layer-bg">
    <div v-if="pop.isShow" class="modal-layer">
      <span class="btn-close type1" @click="pop.isShow = false"><i class="fas fa-times"></i></span>
      <img src="@/assets/img/j_sample.gif" />
    </div>
    <div class="carrot-layer w-800px h-800px">
      <div>
        <p class="title">Job Rotation System</p>
        <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
      </div>
      <div class="mt-10 tbl-light">
        <div class="dp-table w-100per">
          <button @click="pop.showSample()" class="btn-default float-right">작성 샘플보기</button>
        </div>
        <div class="mt-10 overhidden overscroll maxh-600px">
          <table class="tbl-light-list">
            <colgroup>
              <col width="80" />
              <col width="*" />
              <col width="150" />
              <col width="150" />
              <col width="150" />
            </colgroup>
            <thead>
              <tr>
                <th>시행 년도</th>
                <th>소속</th>
                <th>이름</th>
                <th>직급/직급</th>
                <th>입사일자</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ pop.tgyear }}</td>
                <td>{{ pop.info.office_name }} / {{ pop.info.team_name }}</td>
                <td>{{ pop.info.ename }}({{ pop.info.kname }})</td>
                <td>{{ pop.info.position_name }}</td>
                <td>{{ pop.info.joinday }}</td>
              </tr>
            </tbody>
          </table>

          <div class="block-title mt-40">직무 로테이션 (조건: 입사 1년 이상)</div>
          <div class="block-tip">- 현 직무 외 내가 도전해 보고 싶은 직무가 있다면 희망하는 직무와 본인의 강점을 작성해주세요.</div>
          <table class="tbl-light-view mt-10">
            <colgroup>
              <col width="120" />
              <col width="*" />
            </colgroup>
            <tbody>
              <template v-for="(irow, i) in pop.rotation_1" :key="i">
                <tr>
                  <th>{{ i + 1 }}지망</th>
                  <td>
                    <input type="text" v-model.trim="irow.title" class="w-100per" maxlength="50" />
                  </td>
                </tr>
                <tr>
                  <th>나의 강점</th>
                  <td>
                    <textarea v-model="irow.memo" class="w-100per h-100px" maxlength="500"></textarea>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

          <div class="block-title mt-40">조직 로테이션 (조건: 입사 1년 이상)</div>
          <div class="block-tip">- 현 조직 외 함께 일하고 싶은 조직이 있다면 조직명과 희망사유를 작성해주세요.</div>
          <table class="tbl-light-view mt-10">
            <colgroup>
              <col width="120" />
              <col width="*" />
            </colgroup>
            <tbody>
              <template v-for="(irow, i) in pop.rotation_2" :key="i">
                <tr>
                  <th>{{ i + 1 }}지망</th>
                  <td>
                    <CarrotDept v-model="irow.idx_office" class="w-200px"></CarrotDept>
                    <CarrotTeam v-model:idx_office.sync="irow.idx_office" v-model="irow.idx_team" class="w-200px ml-10"></CarrotTeam>
                  </td>
                </tr>
                <tr>
                  <th>희망 이유</th>
                  <td>
                    <textarea v-model="irow.memo" class="w-100per h-100px" maxlength="500"></textarea>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="color-1 mt-10">※ 작성하는 모든 내용은 보안 유지되며 다음 해 인사발령 시 반영됩니다.</div>
        </div>
      </div>
      <div class="layer-btn mt-10 w-100per dp-table">
        <button @click="pop.onClose()" class="btn-default float-left">취소</button>
        <button @click="pop.doSubmit()" class="btn-default float-right">제출하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import Swal from "sweetalert2";

export default {
  name: "JobRotationApplicantViewPopup",
  props: {
    idx: {
      type: [Number, String],
      default: 0,
    },
  },
  setup(props, { emit }) {
    const toast = useToast();

    const pop = reactive({
      isShow: false,

      idx: 0,
      info: {},

      tgyear: "",
      rotation_1: [
        { title: "", memo: "" },
        { title: "", memo: "" },
        { title: "", memo: "" },
      ],
      rotation_2: [
        { title: "", idx_office: "0", idx_team: "0", memo: "" },
        { title: "", idx_office: "0", idx_team: "0", memo: "" },
        { title: "", idx_office: "0", idx_team: "0", memo: "" },
      ],

      showSample: () => {
        pop.isShow = true;
      },

      doSearch: () => {
        let params = {};

        axios.get("/rest/component/getStaffInfo", { params: params }).then((res) => {
          if (res.data.err == 0) {
            pop.info = res.data;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      doSubmit: () => {
        let params = {
          idx: pop.idx,
          rotation_1: pop.rotation_1,
          rotation_2: pop.rotation_2,
        };

        axios.post("/rest/mypims/addJRApplicant", params).then((res) => {
          if (res.data.err == 0) {
            Swal.fire({
              title: "Job Rotation System",
              text: "제출하였습니다.",
            }).then(() => {
              pop.onClose();
            });
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      onClose: () => {
        emit("close");
      },
    });

    onMounted(() => {
      // Mounted
      pop.idx = props.idx;

      let td = new Date();
      pop.tgyear = td.getFullYear();

      pop.doSearch();
    });

    return { pop };
  },
};
</script>
<style lang="scss" scoped>
.modal-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 800px;
  height: 800px;
  margin: auto;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  .btn-close {
    position: absolute;
    top: 10px;
    right: 85px;
    width: 20px;
    height: 20px;
    padding: 0;
    margin-top: 0;
    margin-right: 0;
    cursor: pointer;
    [class^="fas"],
    [class^="fa"] {
      content: url(~@/assets/img/ico_pop_close2.png);
    }
  }
}
</style>
