import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import axios from "@/plugins/axios.js";
import VueAxios from "vue-axios";

import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";

import CarrotHeader from "@/components/common/CarrotHeader.vue";
import CarrotPaging from "@/components/common/CarrotPaging.vue";
import CarrotTitle from "@/components/common/CarrotTitle.vue";
import CarrotDept from "@/components/common/CarrotDept.vue";
import CarrotTeam from "@/components/common/CarrotTeam.vue";
import CarrotTimeSelect from "@/components/common/CarrotTimeSelect.vue";
import CarrotFileUploader from "@/components/common/CarrotFileUploader.vue";
import CarrotPosition from "@/components/common/CarrotPosition.vue";

const app = createApp(App).use(store).use(router);
app.use(Toast, {
  position: POSITION.BOTTOM_CENTER,
});

app.use(VueAxios, axios);

app.use(VCalendar, {
  masks: { input: "YYYY-MM-DD" },
});

app.component("CarrotHeader", CarrotHeader);
app.component("CarrotPaging", CarrotPaging);
app.component("CarrotTitle", CarrotTitle);
app.component("CarrotDept", CarrotDept);
app.component("CarrotTeam", CarrotTeam);
app.component("CarrotTimeSelect", CarrotTimeSelect);
app.component("CarrotFileUploader", CarrotFileUploader);
app.component("CarrotPosition", CarrotPosition);

app.mount("#app");

app.config.globalProperties.axios = axios;

if (!store.state.email) {
  store.commit("setUserInfo", store.state.gnb);
  store.commit("setAuth");
}
