import { createRouter, createWebHistory } from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'

const RouterLayout = createRouterLayout(layout => {
  //console.log(layout);
  return import('@/layouts/' + layout + '.vue')
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes
    },
    {
      name: "documents-ExpenseInputModify",
      path: '/documents/ExpenseInputModify/:id',
      component:  () => import('@/pages/documents/ExpenseInputAdd.vue'),
    },
    {
      name: "documents-DraftingModify",
      path: '/documents/DraftingModify/:id',
      component:  () => import('@/pages/documents/DraftingAdd.vue'),
    },
    {
      name: "documents-SalesManagementPublishView",
      path: '/documents/SalesManagementPublishView/:id',
      component:  () => import('@/pages/documents/SalesManagementPublishView.vue'),
    },
    {
      name: "documents-SalesManagementPublishModify",
      path: '/documents/SalesManagementPublishModify/:id',
      component:  () => import('@/pages/documents/SalesManagementPublishAdd.vue'),
    }
  ]
})

export default router
